.root {
  width: 100%;
  padding: 20px;
  padding-top: 30px;
  height: 100%;
}

.top {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.addPurchasesList {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d6efd;
}

.icon {
  cursor: pointer;
}
